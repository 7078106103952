<template>
    <div>
        <div class="block-category-title">Call to Action</div>
        <BlockViewer header="Split Content" :code="block1">
            <div class="surface-section px-4 py-8 md:px-6 lg:px-8">
                <div class="flex flex-column lg:flex-row align-items-center justify-content-between">
                    <div class="text-center lg:text-left lg:pr-8">
                        <div class="text-blue-600 font-medium mb-1 text-xl">TAKE THE NEXT STEP</div>
                        <div class="text-900 font-bold text-6xl mb-5">Get Started with PrimeBlocks</div>

                        <span class="inline-flex align-items-center bg-blue-50 px-3 py-2 mr-2 mb-2 lg:mb-0 border-1 border-blue-200" style="border-radius: 50px">
                            <i class="pi pi-check text-blue-500 mr-2"></i>
                            <span class="text-gray-700 font-medium">1 Month Free Trial</span>
                        </span>
                        <span class="inline-flex align-items-center bg-blue-50 px-3 py-2 border-1 border-blue-200" style="border-radius: 50px">
                            <i class="pi pi-check text-blue-500 mr-2"></i>
                            <span class="text-gray-700 font-medium">No Credit Card Required</span>
                        </span>
                    </div>
                    <div class="mt-5 lg:mt-0 flex flex-wrap lg:flex-nowrap">
                        <Button label="Get Started" class="px-5 py-3 mr-0 lg:mr-3 p-button-rounded font-bold mb-3 lg:mb-0 w-full lg:w-auto white-space-nowrap"></Button>
                        <Button label="Request a Demo" icon="pi pi-arrow-right" iconPos="right" class="px-5 py-3 p-button-outlined p-button-rounded font-bold w-full lg:w-auto white-space-nowrap" style="white-space: nowrap"></Button>
                    </div>
                </div>
            </div>
        </BlockViewer>

        <BlockViewer header="Dark Background with Image" :code="block2">
            <div class="bg-gray-900 px-4 py-8 md:px-6 lg:px-8">
                <div class="flex justify-content-between align-items-start flex-wrap lg:flex-nowrap">
                    <div class="pr-0 lg:pr-6 mb-5 lg:mb-0">
                        <div class="font-bold text-white text-6xl font-bold mb-3">Enhanced User Experience</div>
                        <div class="text-gray-300 line-height-3 mb-4">Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</div>
                        <div class="flex flex-wrap lg:flex-nowrap">
                            <Button class="px-5 py-3 font-bold mr-3 white-space-nowrap mb-3 lg:mb-0 w-full lg:w-auto" label="Learn More"></Button>
                            <Button class="px-5 py-3 font-bold mr-3 p-button-text white-space-nowrap w-full lg:w-auto" label="Contact Sales"></Button>
                        </div>
                    </div>
                    <img src="images/blocks/cta/cta-1.png" alt="Image" class="block mx-auto lg:mx-0" />
                </div>
            </div>
        </BlockViewer>

        <BlockViewer header="Image Background" :code="block3">
            <div class="bg-gray-900 p-8 flex justify-content-center md:justify-content-end bg-no-repeat bg-cover md:bg-contain" style="background-image: url('images/blocks/cta/cta-2.png')">
                <div class="px-5">
                    <div class="text-white font-bold text-6xl">Ready to build beyond</div>
                    <div class="text-blue-200 font-bold text-6xl">your expectations?</div>
                    <div class="mt-3 mb-5 text-gray-200 font-medium line-height-3">Vestibulum sed arcu non odio euismod lacinia at quis risus. Massa massa ultricies mi quis.</div>
                    <Button label="Get Started" class="font-bold px-5 py-3"></Button>
                </div>
            </div>
        </BlockViewer>

        <BlockViewer header="Gradient Background" :code="block4">
            <div class="surface-section px-4 py-8 md:px-6 lg:px-8">
                <div class="p-6 shadow-2 flex flex-column md:flex-row align-items-center justify-content-between" style="border-radius: 1rem; background: linear-gradient(0deg, rgba(0, 123, 255, 0.5), rgba(0, 123, 255, 0.5)), linear-gradient(92.54deg, #1c80cf 47.88%, #ffffff 100.01%)">
                    <div class="pr-6">
                        <div class="text-blue-100 font-medium text-xl mb-3">TAKE THE NEXT STEP</div>
                        <div class="text-white font-medium text-5xl">Enpower your customer experience</div>
                    </div>
                    <div class="mt-4 mr-auto md:mt-0 md:mr-0">
                        <Button label="Get Started" class="font-bold px-5 py-3 p-button-warning p-button-rounded p-button-raised white-space-nowrap"></Button>
                    </div>
                </div>
            </div>
        </BlockViewer>

        <BlockViewer header="Simple Centered" :code="block5" :free="true">
            <div class="surface-section px-4 py-8 md:px-6 lg:px-8">
                <div class="text-700 text-center">
                    <div class="text-blue-600 font-bold mb-3"><i class="pi pi-discord"></i>&nbsp;POWERED BY DISCORD</div>
                    <div class="text-900 font-bold text-5xl mb-3">Join Our Design Community</div>
                    <div class="text-700 text-2xl mb-5">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Velit numquam eligendi quos.</div>
                    <Button label="Join Now" icon="pi pi-discord" class="font-bold px-5 py-3 p-button-raised p-button-rounded white-space-nowrap"></Button>
                </div>
            </div>
        </BlockViewer>

        <BlockViewer header="Simple with Date Selector" :code="block6">
            <div class="surface-section px-4 py-8 md:px-6 lg:px-8">
                <div class="grid">
                    <div class="col-12 md:col-6">
                        <Button label="Travel Update" icon="pi pi-chevron-right" iconPos="right" class="p-button-success p-button-rounded mb-3 font-bold"></Button>
                        <div class="text-900 font-bold text-5xl mb-3">Book Flights</div>
                        <div class="text-600 text-2xl line-height-3">Find cheap flights and airline tickets.</div>
                        <div class="text-600 text-2xl line-height-3">Prime Flights helps you compare and track airfares.</div>
                    </div>
                    <div class="col-12 md:col-6 flex align-items-center md:justify-content-end">
                        <div>
                            <div class="text-900 font-medium justify-content-start mb-3">Round Trip Dates</div>
                            <Calendar v-model="date1" dateFormat="dd/mm/yy" :showIcon="true" selectionMode="range" :style="{ 'min-width': '240px' }" inputClass="'bg-transparent text-900 border-400'"></Calendar>
                            <Button label="Book Now" icon="pi pi-check" class="block mt-3 font-bold white-space-nowrap p-button-outlined w-full"></Button>
                        </div>
                    </div>
                </div>
            </div>
        </BlockViewer>

        <BlockViewer header="Dark background with date selector" :code="block7">
            <div class="bg-gray-900 px-4 py-8 md:px-6 lg:px-8">
                <div class="grid">
                    <div class="col-12 md:col-6">
                        <Button label="Travel Resticted" icon="pi pi-chevron-right" iconPos="right" class="p-button-help p-button-rounded mb-3 font-bold"></Button>
                        <div class="text-white font-bold text-5xl mb-3">Book Flights</div>
                        <div class="text-gray-400 text-2xl line-height-3">Find cheap flights and airline tickets.</div>
                        <div class="text-gray-400 text-2xl line-height-3">Prime Flights helps you compare and track airfares.</div>
                    </div>
                    <div class="col-12 md:col-6 flex align-items-center md:justify-content-end">
                        <div>
                            <div class="text-gray-400 font-medium justify-content-start mb-3">Round Trip Dates</div>
                            <Calendar v-model="date2" dateFormat="dd/mm/yy" :showIcon="true" selectionMode="range" :style="{ 'min-width': '240px' }" inputClass="bg-transparent text-gray-400 border-gray-400"></Calendar>
                            <Button label="Book Now" icon="pi pi-check" class="block mt-3 font-bold white-space-nowrap w-full"></Button>
                        </div>
                    </div>
                </div>
            </div>
        </BlockViewer>

        <BlockViewer header="Branded with background image" :code="block8">
            <div class="relative lg:p-8 p-6 text-0 overflow-hidden" style="background: url('images/blocks/cta/cta-3.jpg') no-repeat; background-size: cover">
                <div class="text-center my-6 relative">
                    <div class="text-6xl mb-3 text-white flex justify-content-center flex-wrap">
                        <div class="font-bold">Discover&nbsp;</div>
                        True Power
                    </div>
                    <div class="text-2xl text-gray-400 line-height-3 text-center mx-auto mb-5" style="max-width: 500px">Your perfect companion for Angular featuring elegant, high-performance, accessible and fully customizable UI Components.</div>
                    <Button label="Learn More" class="font-bold px-5 py-3 mr-0 sm:mr-4 p-button-warning p-button-rounded p-button-raised white-space-nowrap"></Button>
                    <Button label="Live Demo" class="font-bold px-5 py-3 p-button-warning p-button-rounded p-button-raised p-button-outlined white-space-nowrap"></Button>
                </div>
            </div>
        </BlockViewer>

        <BlockViewer header="Two Columns with Images" :code="block9">
            <div class="bg-indigo-600 px-4 py-8 md:px-6 lg:px-8">
                <div class="flex flex-column lg:flex-row">
                    <div class="w-full lg:w-6 p-6 bg-indigo-500 mr-0 lg:mr-3 mb-5 lg:mb-0" style="border-radius: 12px">
                        <div class="text-white text-4xl font-medium mb-3">Magna fringilla urna $0 per month</div>
                        <p class="line-height-3 text-indigo-100 text-xl mt-0 mb-4">Quisque sagittis purus sit amet volutpat consequat mauris nunc. Scelerisque eleifend donec pretium vulputate sapien nec sagittis aliquam malesuada.</p>
                        <Button type="button" label="Try It Free" class="p-button-warning font-medium p-button-rounded"></Button>
                    </div>
                    <div class="w-full lg:w-6 p-6 bg-indigo-500 ml-0 lg:ml-3" style="border-radius: 12px">
                        <div class="mb-3">
                            <img src="images/blocks/avatars/circle/avatar-f-1.png" class="w-3rem mr-5 mb-3" />
                            <img src="images/blocks/avatars/circle/avatar-m-1.png" class="w-3rem mr-5 mb-3" />
                            <img src="images/blocks/avatars/circle/avatar-f-2.png" class="w-3rem mr-5 mb-3" />
                            <img src="images/blocks/avatars/circle/avatar-m-2.png" class="w-3rem mr-5 mb-3" />
                            <img src="images/blocks/avatars/circle/avatar-f-3.png" class="w-3rem mr-5 mb-3" />
                            <img src="images/blocks/avatars/circle/avatar-m-3.png" class="w-3rem mr-5 mb-3" />
                            <img src="images/blocks/avatars/circle/avatar-f-4.png" class="w-3rem mr-5 mb-3" />
                            <img src="images/blocks/avatars/circle/avatar-m-4.png" class="w-3rem mr-5 mb-3" />
                            <img src="images/blocks/avatars/circle/avatar-f-5.png" class="w-3rem mr-5 mb-3" />
                            <img src="images/blocks/avatars/circle/avatar-m-5.png" class="w-3rem mr-5 mb-3" />
                            <img src="images/blocks/avatars/circle/avatar-f-6.png" class="w-3rem mr-5 mb-3" />
                            <img src="images/blocks/avatars/circle/avatar-m-6.png" class="w-3rem mr-5 mb-3" />
                            <img src="images/blocks/avatars/circle/avatar-f-7.png" class="w-3rem mr-5 mb-3" />
                            <img src="images/blocks/avatars/circle/avatar-m-7.png" class="w-3rem mr-5 mb-3" />
                            <img src="images/blocks/avatars/circle/avatar-f-8.png" class="w-3rem mr-5 mb-3" />
                            <img src="images/blocks/avatars/circle/avatar-m-8.png" class="w-3rem mr-5 mb-3" />
                        </div>
                        <div class="text-white text-4xl font-medium mb-3">Join our community of 100,000 users</div>
                        <p class="line-height-3 text-indigo-100 text-xl m-0">Eget nulla facilisi etiam dignissim diam quis enim lobortis scelerisque. At ultrices mi tempus imperdiet nulla malesuada.</p>
                    </div>
                </div>
            </div>
        </BlockViewer>

        <BlockViewer header="With Browser Mockup" :code="block10">
            <div class="surface-ground px-4 py-8 md:px-6 lg:px-8">
                <div class="flex flex-wrap relative overflow-hidden" style="border-radius: 1rem; background: radial-gradient(100% 1126.49% at 100% 0%, rgba(106, 200, 212, 0.4) 0%, rgba(33, 33, 33, 0) 100%), #212121">
                    <div class="px-6 py-8">
                        <span class="uppercase text-cyan-500 font-bold">BE THE FIRST ONE</span>
                        <div class="text-white text-medium text-5xl mt-3 mb-5">Join the waitlist Today!</div>
                        <button class="appearance-none p-3 bg-cyan-500 hover:bg-cyan-300 cursor-pointer font-medium gray-900 border-none p-component transition-colors transition-duration-150" style="border-radius: 35px">Reserve Your Username</button>
                    </div>

                    <img src="images/blocks/cta/cta-browser.png" alt="Image" class="static lg:absolute block mx-auto" style="height: 400px; top: -2rem; right: -1rem" />
                </div>
            </div>
        </BlockViewer>

        <BlockViewer header="Two Columns with CTA" :code="block11">
            <div class="surface-ground px-4 py-8 md:px-6 lg:px-8">
                <div class="grid grid-nogutter p-2 lg:p-4 bg-cyan-50 border-round mb-4 text-center lg:text-left">
                    <div class="col-12 lg:col-6 p-4 flex flex-column justify-content-center">
                        <span class="text-3xl block text-cyan-900 font-bold">Get Deals and Updates from Peak</span>
                        <span class="block text-cyan-600 mt-3">We promise for not sending spam emails. It’ll only good emails.</span>
                        <div class="p-inputgroup relative mt-4" style="border-radius: 30px; max-width: 90%">
                            <InputText type="text" placeholder="Email" style="border-radius: 30px; height: 3.5rem" class="bg-white-alpha-90 text-black-alpha-90 px-4 border-cyan-100" />
                            <Button type="button" label="Subscribe" class="bg-cyan-500 border-none absolute text-white z-1" style="border-radius: 30px; right: 8px; top: 8px; height: 2.5rem"></Button>
                        </div>
                    </div>
                    <div class="col-12 lg:col-6 p-4">
                        <div class="w-full h-full bg-no-repeat bg-center bg-cover p-5" style="background: url(images/blocks/ecommerce/categorypage/categorypage-1-1.png); border-radius: 30px">
                            <span class="text-cyan-500 font-bold block">Exclusive Peak Club</span>
                            <span class="text-white text-xl font-bold block mt-3 line-height-3">Join Exlusive Peak Club for free shipping, premium service and deals.</span>
                            <Button type="button" label="Get Your Card Today" class="bg-cyan-500 border-none w-full mt-3 text-white" style="border-radius: 30px"></Button>
                        </div>
                    </div>
                </div>
            </div>
        </BlockViewer>

        <BlockViewer header="Overlapping Image" :code="block12" recent>
            <div class="surface-section flex flex-column lg:flex-row px-4 py-8 md:px-6 lg:px-8 align-items-center">
                <img src="images/blocks/cta/cta-building.png" class="h-25rem border-round hidden lg:block" />
                <div style="background: url(images/blocks/cta/row.jpg)" class="bg-no-repeat bg-cover lg:border-noround-left border-round-xl px-4 py-7 sm:px-7 md:px-6">
                    <img src="images/blocks/cta/cta-building.png" class="w-full sm:w-15rem border-round block lg:hidden mx-auto mb-5" />
                    <div class="text-indigo-50 text-4xl font-semibold mb-3">Supercharge your workflow</div>
                    <p class="text-indigo-100 mt-0 mb-5 line-height-3">Phasellus vestibulum lorem sed risus ultricies. Nulla pharetra diam sit amet nisl suscipit adipiscing bibendum est. Aenean euismod elementum nisi quis eleifend quam.</p>
                    <Button type="button" v-ripple icon="pi pi-wallet" label="Start Free Trial" class="p-button-warning px-5 font-bold white-space-nowrap"></Button>
                </div>
            </div>
        </BlockViewer>
    </div>
</template>

<script>
export default {
    name: 'CTA',
    data() {
        return {
            date1: '31/03/2021 - 01/04/2021',
            date2: '31/03/2021 - 01/04/2021',
            block1: `
<div class="surface-section px-4 py-8 md:px-6 lg:px-8">
    <div class="flex flex-column lg:flex-row align-items-center justify-content-between">
        <div class="text-center lg:text-left lg:pr-8">
            <div class="text-blue-600 font-medium mb-1 text-xl">TAKE THE NEXT STEP</div>
            <div class="text-900 font-bold text-6xl mb-5">Get Started with PrimeBlocks</div>

            <span class="inline-flex align-items-center bg-blue-50 px-3 py-2 mr-2 mb-2 lg:mb-0 border-1 border-blue-200" style="border-radius: 50px">
                <i class="pi pi-check text-blue-500 mr-2"></i>
                <span class="text-gray-700 font-medium">1 Month Free Trial</span>
            </span>
            <span class="inline-flex align-items-center bg-blue-50 px-3 py-2 border-1 border-blue-200" style="border-radius: 50px">
                <i class="pi pi-check text-blue-500 mr-2"></i>
                <span class="text-gray-700 font-medium">No Credit Card Required</span>
            </span>
        </div>
        <div class="mt-5 lg:mt-0 flex flex-wrap lg:flex-nowrap">
            <Button label="Get Started" class="px-5 py-3 mr-0 lg:mr-3 p-button-rounded font-bold mb-3 lg:mb-0 w-full lg:w-auto white-space-nowrap"></Button>
            <Button label="Request a Demo" icon="pi pi-arrow-right" iconPos="right" class="px-5 py-3 p-button-outlined p-button-rounded font-bold w-full lg:w-auto white-space-nowrap" style="white-space: nowrap"></Button>
        </div>
    </div>
</div>`,
            block2: `
<div class="bg-gray-900 px-4 py-8 md:px-6 lg:px-8">
    <div class="flex justify-content-between align-items-start flex-wrap lg:flex-nowrap">
        <div class="pr-0 lg:pr-6 mb-5 lg:mb-0">
            <div class="font-bold text-white text-6xl font-bold mb-3">Enhanced User Experience</div>
            <div class="text-gray-300 line-height-3 mb-4">Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</div>
            <div class="flex flex-wrap lg:flex-nowrap">
                <Button class="px-5 py-3 font-bold mr-3 white-space-nowrap mb-3 lg:mb-0 w-full lg:w-auto" label="Learn More"></Button>
                <Button class="px-5 py-3 font-bold mr-3 p-button-text white-space-nowrap w-full lg:w-auto" label="Contact Sales"></Button>
            </div>
        </div>
        <img src="images/blocks/cta/cta-1.png" alt="Image" class="block mx-auto lg:mx-0">
    </div>
</div>`,
            block3: `
<div class="bg-gray-900 p-8 flex justify-content-center md:justify-content-end bg-no-repeat bg-cover md:bg-contain" style="background-image: url('images/blocks/cta/cta-2.png')">
    <div class="px-5">
        <div class="text-white font-bold text-6xl">Ready to build beyond</div>
        <div class="text-blue-200 font-bold text-6xl">your expectations?</div>
        <div class="mt-3 mb-5 text-gray-200 font-medium line-height-3">Vestibulum sed arcu non odio euismod lacinia at quis risus. Massa massa ultricies mi quis. </div>
        <Button label="Get Started" class="font-bold px-5 py-3"></Button>
    </div>
</div>`,
            block4: `
<div class="surface-section px-4 py-8 md:px-6 lg:px-8">
    <div class="p-6 shadow-2 flex flex-column md:flex-row align-items-center justify-content-between" style="border-radius: 1rem; background: linear-gradient(0deg, rgba(0, 123, 255, 0.5), rgba(0, 123, 255, 0.5)), linear-gradient(92.54deg, #1C80CF 47.88%, #FFFFFF 100.01%)">
        <div class="pr-6">
            <div class="text-blue-100 font-medium text-xl mb-3">TAKE THE NEXT STEP</div>
            <div class="text-white font-medium text-5xl">Enpower your customer experience</div>
        </div>
        <div class="mt-4 mr-auto md:mt-0 md:mr-0">
            <Button label="Get Started" class="font-bold px-5 py-3 p-button-warning p-button-rounded p-button-raised white-space-nowrap"></Button>
        </div>
    </div>
</div>`,
            block5: `
<div class="surface-section px-4 py-8 md:px-6 lg:px-8">
    <div class="text-700 text-center">
        <div class="text-blue-600 font-bold mb-3"><i class="pi pi-discord"></i>&nbsp;POWERED BY DISCORD</div>
        <div class="text-900 font-bold text-5xl mb-3">Join Our Design Community</div>
        <div class="text-700 text-2xl mb-5">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Velit numquam eligendi quos.</div>
        <Button label="Join Now" icon="pi pi-discord" class="font-bold px-5 py-3 p-button-raised p-button-rounded white-space-nowrap"></Button>
    </div>
</div>`,
            block6: `
<div class="surface-section px-4 py-8 md:px-6 lg:px-8">
    <div class="grid">
        <div class="col-12 md:col-6">
            <Button label="Travel Update" icon="pi pi-chevron-right" iconPos="right" class="p-button-success p-button-rounded mb-3 font-bold"></Button>
            <div class="text-900 font-bold text-5xl mb-3">Book Flights</div>
            <div class="text-600 text-2xl line-height-3">Find cheap flights and airline tickets.</div>
            <div class="text-600 text-2xl line-height-3">Prime Flights helps you compare and track airfares.</div>
        </div>
        <div class="col-12 md:col-6 flex align-items-center md:justify-content-end">
            <div>
                <div class="text-900 font-medium justify-content-start mb-3">Round Trip Dates</div>
                <Calendar v-model="date1" dateFormat="dd/mm/yy" :showIcon="true" selectionMode="range" :style="{'min-width': '240px'}" inputClass="'bg-transparent text-900 border-400'"></Calendar>
                <Button label="Book Now" icon="pi pi-check" class="block mt-3 font-bold white-space-nowrap p-button-outlined w-full"></Button>
            </div>
        </div>
    </div>
</div>`,
            block7: `
<div class="bg-gray-900 px-4 py-8 md:px-6 lg:px-8">
    <div class="grid">
        <div class="col-12 md:col-6">
            <Button label="Travel Resticted" icon="pi pi-chevron-right" iconPos="right" class="p-button-help p-button-rounded mb-3 font-bold"></Button>
            <div class="text-white font-bold text-5xl mb-3">Book Flights</div>
            <div class="text-gray-400 text-2xl line-height-3">Find cheap flights and airline tickets.</div>
            <div class="text-gray-400 text-2xl line-height-3">Prime Flights helps you compare and track airfares.</div>
        </div>
        <div class="col-12 md:col-6 flex align-items-center md:justify-content-end">
            <div>
                <div class="text-gray-400 font-medium justify-content-start mb-3">Round Trip Dates</div>
                <Calendar v-model="date2" dateFormat="dd/mm/yy" :showIcon="true" selectionMode="range" :style="{'min-width': '240px'}" inputClass="bg-transparent text-gray-400 border-gray-400"></Calendar>
                <Button label="Book Now" icon="pi pi-check" class="block mt-3 font-bold white-space-nowrap w-full"></Button>
            </div>
        </div>
    </div>
</div>`,
            block8: `
<div class="relative lg:p-8 p-6 text-0 overflow-hidden" style="background: url('images/blocks/cta/cta-3.jpg') no-repeat; background-size: cover">
    <div class="text-center my-6 relative">
        <div class="text-6xl mb-3 text-white flex justify-content-center flex-wrap">
            <div class="font-bold">Discover&nbsp;</div>
            True Power
        </div>
        <div class="text-2xl text-gray-400 line-height-3 text-center mx-auto mb-5" style="max-width: 500px">Your perfect companion for Angular featuring elegant, high-performance, accessible and fully customizable UI Components.</div>
        <Button label="Learn More" class="font-bold px-5 py-3 mr-0 sm:mr-4 p-button-warning p-button-rounded p-button-raised white-space-nowrap"></Button>
        <Button label="Live Demo" class="font-bold px-5 py-3 p-button-warning p-button-rounded p-button-raised p-button-outlined white-space-nowrap"></Button>
    </div>
</div>`,
            block9: `
<div class="bg-indigo-600 px-4 py-8 md:px-6 lg:px-8">
    <div class="flex flex-column lg:flex-row">
        <div class="w-full lg:w-6 p-6 bg-indigo-500 mr-0 lg:mr-3 mb-5 lg:mb-0" style="border-radius: 12px">
            <div class="text-white text-4xl font-medium mb-3">Magna fringilla urna $0 per month</div>
            <p class="line-height-3 text-indigo-100 text-xl mt-0 mb-4">Quisque sagittis purus sit amet volutpat consequat mauris nunc.
                Scelerisque eleifend donec pretium vulputate sapien nec sagittis aliquam malesuada.</p>
            <Button type="button" label="Try It Free" class="p-button-warning font-medium p-button-rounded"></Button>
        </div>
        <div class="w-full lg:w-6 p-6 bg-indigo-500 ml-0 lg:ml-3" style="border-radius: 12px">
            <div class="mb-3">
                <img src="images/blocks/avatars/circle/avatar-f-1.png" class="w-3rem mr-5 mb-3" />
                <img src="images/blocks/avatars/circle/avatar-m-1.png" class="w-3rem mr-5 mb-3" />
                <img src="images/blocks/avatars/circle/avatar-f-2.png" class="w-3rem mr-5 mb-3" />
                <img src="images/blocks/avatars/circle/avatar-m-2.png" class="w-3rem mr-5 mb-3" />
                <img src="images/blocks/avatars/circle/avatar-f-3.png" class="w-3rem mr-5 mb-3" />
                <img src="images/blocks/avatars/circle/avatar-m-3.png" class="w-3rem mr-5 mb-3" />
                <img src="images/blocks/avatars/circle/avatar-f-4.png" class="w-3rem mr-5 mb-3" />
                <img src="images/blocks/avatars/circle/avatar-m-4.png" class="w-3rem mr-5 mb-3" />
                <img src="images/blocks/avatars/circle/avatar-f-5.png" class="w-3rem mr-5 mb-3" />
                <img src="images/blocks/avatars/circle/avatar-m-5.png" class="w-3rem mr-5 mb-3" />
                <img src="images/blocks/avatars/circle/avatar-f-6.png" class="w-3rem mr-5 mb-3" />
                <img src="images/blocks/avatars/circle/avatar-m-6.png" class="w-3rem mr-5 mb-3" />
                <img src="images/blocks/avatars/circle/avatar-f-7.png" class="w-3rem mr-5 mb-3" />
                <img src="images/blocks/avatars/circle/avatar-m-7.png" class="w-3rem mr-5 mb-3" />
                <img src="images/blocks/avatars/circle/avatar-f-8.png" class="w-3rem mr-5 mb-3" />
                <img src="images/blocks/avatars/circle/avatar-m-8.png" class="w-3rem mr-5 mb-3" />
            </div>
            <div class="text-white text-4xl font-medium mb-3">Join our community of 100,000 users</div>
            <p class="line-height-3 text-indigo-100 text-xl m-0">Eget nulla facilisi etiam dignissim diam quis enim lobortis scelerisque. At ultrices mi tempus imperdiet nulla malesuada.</p>
        </div>
    </div>
</div>`,
            block10: `
<div class="surface-ground px-4 py-8 md:px-6 lg:px-8">
    <div class="flex flex-wrap relative overflow-hidden" style="border-radius:1rem; background:radial-gradient(100% 1126.49% at 100% 0%, rgba(106, 200, 212, 0.4) 0%, rgba(33, 33, 33, 0) 100%), #212121;">
        <div class="px-6 py-8">
            <span class="uppercase text-cyan-500 font-bold">BE THE FIRST ONE</span>
            <div class="text-white text-medium text-5xl mt-3 mb-5">Join the waitlist Today!</div>
            <button class="appearance-none p-3 bg-cyan-500 hover:bg-cyan-300 cursor-pointer font-medium gray-900 border-none p-component transition-colors transition-duration-150" style="border-radius: 35px">Reserve Your Username</button>
        </div>

        <img src="images/blocks/cta/cta-browser.png" alt="Image" class="static lg:absolute block mx-auto" style="height: 400px; top: -2rem; right: -1rem">
    </div>
</div>`,
            block11: `
<div class="surface-ground px-4 py-8 md:px-6 lg:px-8">
    <div class="grid grid-nogutter p-2 lg:p-4 bg-cyan-50 border-round mb-4 text-center lg:text-left">
        <div class="col-12 lg:col-6 p-4 flex flex-column justify-content-center">
            <span class="text-3xl block text-cyan-900 font-bold">Get Deals and Updates from Peak</span>
            <span class="block text-cyan-600 mt-3">We promise for not sending spam emails. It’ll only good emails.</span>
            <div class="p-inputgroup relative mt-4" style="border-radius:30px; max-width: 90%;">
                <InputText type="text" placeholder="Email" style="border-radius:30px; height:3.5rem;" class="bg-white-alpha-90 text-black-alpha-90 px-4 border-cyan-100" />
                <Button type="button" label="Subscribe" class="bg-cyan-500 border-none absolute text-white z-1" style="border-radius: 30px; right: 8px; top: 8px; height: 2.5rem;"></Button>
            </div>
        </div>
        <div class="col-12 lg:col-6 p-4">
            <div class="w-full h-full bg-no-repeat bg-center bg-cover p-5" style="background: url(images/blocks/ecommerce/categorypage/categorypage-1-1.png); border-radius:30px;">
                <span class="text-cyan-500 font-bold block">Exclusive Peak Club</span>
                <span class="text-white text-xl font-bold block mt-3 line-height-3">Join Exlusive Peak Club for free shipping, premium service and deals.</span>
                <Button type="button" label="Get Your Card Today" class="bg-cyan-500 border-none w-full mt-3 text-white" style="border-radius: 30px;"></Button>
            </div>
        </div>
    </div>
</div>`,
            block12: `
<div class="surface-section flex flex-column lg:flex-row px-4 py-8 md:px-6 lg:px-8 align-items-center">
    <img src="images/blocks/cta/cta-building.png" class="h-25rem border-round hidden lg:block" />
    <div style="background: url(images/blocks/cta/row.jpg)" class="bg-no-repeat bg-cover lg:border-noround-left border-round-xl px-4 py-7 sm:pxmd:px-6">
        <img src="images/blocks/cta/cta-building.png" class="w-full sm:w-15rem border-round block lg:hidden mx-auto mb-5" />
        <div class="text-indigo-50 text-4xl font-semibold mb-3">Supercharge your workflow</div>
        <p class="text-indigo-100 mt-0 mb-5 line-height-3">Phasellus vestibulum lorem sed risus ultricies. Nulla pharetra diam sit amet nisl suscipadipiscing bibendum est. Aenean euismod elementum nisi quis eleifend quam.</p>
        <Button type="button" v-ripple icon="pi pi-wallet" label="Start Free Trial" class="p-button-warning px-5 font-bold white-space-nowrap"Button>
    </div>
</div>`
        };
    }
};
</script>
